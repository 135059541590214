#staff {
  margin: auto;
  margin-top: 100px;

  .whiteSection {
    background-color: #fff;
    .textSection {
      max-width: 1400px;
      padding: 50px;
      margin: auto;
      #staffTitle {
        font-family: Poppins;
        font-size: 50px;
        font-style: normal;
        font-weight: 800;
        line-height: 70px;
        letter-spacing: 0em;
        text-align: left;
        color: #3bd3c3;
      }

      #staffIntro {
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
        max-width: 750px;
      }
    }

    #darkEmployees {
      display: flex;
      justify-content: center;

      @media (max-width: 900px) {
        display: none;
      }
      .darkEmployee {
        filter: grayscale(1);
        width: 13%;
        max-width: 165px;

        img {
          width: 135%;
          max-width: 135%;
        }
      }
    }
  }

  #employees {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(285px, 433px));
    justify-content: center;
    gap: 50px;
    max-width: 1400px;
    margin: auto;
    padding-top: 100px;
    padding-bottom: 100px;

    .employee {
      padding-bottom: 100%;
      background-size: cover;
      height: 0;
      background-color: #fff;
      position: relative;
      background-position-y: -10px;
      background-size: 80%;
      background-position-x: center;
      background-repeat: no-repeat;
      overflow: hidden;

      &:hover {
        .employeeInfo {
          background: #ff3253;
          color: #fff;

          .employeeTitle {
            display: none;
          }

          .employeeEmail {
            color: #fff;
          }

          .employeeDescription {
            display: block;
          }
        }
      }

      &.special {
        background-color: #3bd3c3;
        color: #fff;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        padding-bottom: 0;
        padding: 30px;
        text-align: left;

        .specialTitle {
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 27px;
          letter-spacing: 0em;
          text-align: left;
          margin-bottom: 14px;
        }
      }

      .employeeName {
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: center;
      }

      .employeeEmail,
      .employeeTitle {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        color: #808080;
      }

      .employeeInfo {
        background-color: #fff;
        padding: 10px;
        bottom: 0;
        position: absolute;
        width: 100%;
        box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.12);

        .employeeDescription {
          display: none;
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: center;
        }
      }
    }
  }
}
