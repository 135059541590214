#showCases {
  background-color: black;
  color: #fff;
  .innerContainer {
    max-width: 1200px;

    .mainTitle {
      font-family: Poppins;
      font-size: 96px;
      font-style: normal;
      font-weight: 700;
      line-height: 89px;
      letter-spacing: 0em;
      text-align: left;
      text-transform: uppercase;

      @media (max-width: 767px) {
        font-size: 38px;
        line-height: 38px;
      }
    }

    .subTitle {
      font-family: Poppins;
      font-size: 50px;
      font-weight: 500;
      line-height: 70px;
      letter-spacing: 0em;
      text-align: left;
      background: linear-gradient(91.66deg, #3bd3c3 -2.53%, #4c80ae 59.87%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (max-width: 767px) {
        font-size: 32px;
        line-height: 32px;
      }
    }

    .caseStudyPreview {
      display: flex;
      flex-direction: row;
      margin-top: 200px;

      &:nth-child(odd) {
        flex-direction: row-reverse;

        .caseStudyPreviewInfo {
          padding-left: 0;
          padding-right: 30px;
        }
      }

      @media (max-width: 767px) {
        flex-direction: column !important;
        margin-top: 70px;
      }

      .caseStudyPreviewTitle {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

        font-family: Poppins;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 51px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;

        @media (max-width: 767px) {
          font-size: 40px;
        }
      }

      .caseStudyPreviewImage {
        flex: 1;
        height: 0;
        padding-bottom: 31%;
        background-size: cover;

        @media (max-width: 767px) {
          padding-bottom: 65%;
        }
      }

      .caseStudyPreviewInfo {
        flex: 1;
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 767px) {
          padding-left: 0;
          padding-top: 20px;
        }

        .caseStudyPreviewCustomer {
          color: #3bd3c3;
          text-transform: uppercase;
          text-align: left;
        }

        .caseStudyPreviewDescription {
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
          line-height: 22px;
        }

        .seeCaseStudy {
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: right;
          margin-left: auto;
          color: #fff;
        }
      }
    }
  }
}
