#caseStudy {
  .HeroSection {
    &.graySection {
      background-color: #e9e9e9;
    }
  }

  .caseHeader {
    .heroSectionOverlay {
      background-color: black;
      color: white;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .innerContainer {
      text-align: left;
      padding: 100px;

      .logo {
        margin-bottom: 15px;
        border-radius: 100%;
        border: 15px solid white;
        max-width: 150px;
      }

      .caseStudyTitle {
        font-family: Poppins;
        font-size: 96px;
        font-style: normal;
        font-weight: 800;
        line-height: 87px;
        letter-spacing: -0.005em;
        text-align: left;
        color: #fff;
        max-width: 320px;
        margin-bottom: 20px;
      }

      .shortIntro {
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: -0.005em;
        text-align: left;
        color: #fff;
        max-width: 320px;
      }
    }
  }

  .infoSection {
    display: flex;
    max-width: 1600px;
    margin: auto;
    padding-top: 75px;
    padding-bottom: 75px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .leftInfo {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;

      > div {
        padding: 35px;
      }

      .label {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.31em;
        text-align: left;
        text-transform: uppercase;
      }

      .boxTitle {
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: black;
        max-width: 200px;
      }

      .lowerLeftImage {
        display: flex;
        justify-content: flex-start;
        img {
          max-width: 350px;
        }
      }

      .jobName,
      .companyName {
        font-family: Poppins;
        font-size: 36px;
        font-style: normal;
        font-weight: 800;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
        color: white;
      }

      .kiwiBullets {
        flex-grow: 1;
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
        color: #fff;
        .bulletContent {
          font-family: Poppins;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: -0.005em;
          text-align: left;
          color: #fff;
          margin-top: 20px;
        }
      }
    }

    .rightInfo {
      flex: 1;

      > div {
        padding: 30px;
      }

      .label {
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: -0.005em;
        text-align: left;
      }

      .boxDescription {
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: -0.005em;
        text-align: left;

        p {
          text-align: left;
        }
      }

      .description {
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: -0.005em;
        text-align: left;
        color: #fff;

        b {
          font-weight: bold;
        }
      }
    }
  }
  .mobileImages {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .fullpageImage {
    background-size: cover;
    height: 100vh;
    background-position: center;
  }
}
