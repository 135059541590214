.HeroSection {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: hidden;

  .video_big,
  .video_small {
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    background-color: black;
  }

  .heroSectionOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .innerContainer {
    padding: 30px;
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: row;
    flex: 1;
    box-sizing: border-box;
    text-align: center;

    .mainContent {
      width: 100%;
    }
  }
}
