#frontMain {
  @media (max-width: 500px) {
    .innerContainer {
      align-items: flex-end;
      .mainContent {
        display: flex;
        flex-direction: column-reverse;
        align-items: baseline;
        text-align: left;
      }
    }
  }
}

.imageCarousel {
  background-color: red !important;
}

.carouselImage {
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  position: relative;

  &:hover {
    .innerDiv {
      display: flex !important;
    }
  }

  .innerDiv {
    display: none;
    pointer-events: none;
    background-color: rgba($color: #000000, $alpha: 0.58);
    color: #fff;
    text-align: left;
    padding: 10%;
    position: absolute;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;

    .jobName {
      font-family: Poppins;
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 45px;
      letter-spacing: 0em;
      text-align: left;
      text-transform: uppercase;

      @media (max-width: 900px) {
        font-size: 12px;
        line-height: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .jobTitle {
      background: #3bd3c3;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: Poppins;
      font-size: 25px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left;

      @media (max-width: 900px) {
        font-size: 16px;
        line-height: 36px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .jobDescription {
      @media (max-width: 900px) {
        display: none;
      }
    }

    .seeMore {
      text-align: right;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      color: #fff;
      text-transform: uppercase;
      display: inline-block;
      text-decoration: underline;
      pointer-events: all;
    }
  }
}

.mainTitle {
  p {
    color: #fff;
    margin-bottom: 20px !important;

    @media (max-width: 768px) {
      margin-bottom: 0 !important;
    }
  }
}

.fullscreen {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
  background: black;
  top: 0;
  z-index: 99999;

  .closeFullScreen {
    position: absolute;
    right: 15px;
    color: #fff;
    font-size: 50px;
    font-family: sans-serif;
    cursor: pointer;
    z-index: 999;
  }
}
.video {
  display: block;
  left: 0px;
  overflow: hidden;
  padding-bottom: 56.25%; /* 56.25% = 16:9. set ratio */
  position: absolute;
  top: 50%;
  width: 100%;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.video .wrapper {
  display: block;
  height: 300%;
  left: 0px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.video iframe {
  display: block;
  height: 100%;
  width: 100%;
}
.happy {
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: 0.3em;
  text-align: left;
}

#companies {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 14px;
  justify-content: center;
  width: 447px;
  margin: auto;
  padding: 20px;
  padding-top: 0;

  @media (max-width: 900px) {
    grid-template-columns: repeat(auto-fit, minmax(90px, 100px));
    gap: 10px;
    width: 100%;
    padding-top: 20px;
  }
}
