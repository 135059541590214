.voxtur {
  background: #000;
  color: #fff;

  #growth {
    #top {
      padding: 150px;

      .gheader {
        max-width: 700px;
        margin-bottom: 100px;
        .title {
          font-family: Poppins;
          font-size: 50px;
          font-style: normal;
          font-weight: 800;
          line-height: 70px;
          letter-spacing: 0em;
          text-align: left;

          background: linear-gradient(91.66deg, #3bd3c3 -2.53%, #4c80ae 59.87%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .description {
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 300;
          line-height: 30px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      .explanation {
        display: flex;

        .leftSection {
          flex: 1;
          text-align: left;

          .question {
            margin-bottom: 125px;
            .label {
              font-family: Poppins;
              font-weight: medium;
              font-size: 24px;
              font-style: italic;
              font-weight: 400;
              line-height: 43px;
              letter-spacing: 0em;
              text-align: left;
              color: #3bd3c3;
            }

            .title {
              font-family: Poppins;
              font-size: 36px;
              font-style: normal;
              font-weight: 800;
              line-height: 43px;
              letter-spacing: 0em;
              text-align: left;
              text-transform: uppercase;
            }
          }
        }

        .rightSection {
          flex: 1;
          text-align: left;
        }
      }
    }

    .happyCustomers {
      h1 {
        padding-top: 75px;
        font-family: Poppins;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 51px;
        letter-spacing: 0em;
        text-align: center;
        max-width: 650px;
        margin: auto;
      }

      #customerList {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
        margin-top: 50px;
        max-width: 1200px;
        margin: auto;

        .customer {
          padding: 50px;

          img {
            padding-bottom: 20px;
            max-width: 75px;
          }

          .seeCaseStudy {
            padding-top: 20px;
          }
        }
      }
    }
  }
}
