#showCase {
  background-color: #000;
  min-height: 100vh;
  color: #fff;

  #videos {
    margin-bottom: 40px;
  }

  .infoSection {
    display: flex;
    max-width: 1600px;
    margin: auto;
    padding-top: 75px;
    padding-bottom: 75px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .leftInfo {
      display: flex;
      flex-direction: column;
      flex: 1;

      > div {
        padding: 35px;
      }

      .label {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.31em;
        text-align: left;
        text-transform: uppercase;
      }

      .jobName,
      .companyName {
        font-family: Poppins;
        font-size: 36px;
        font-style: normal;
        font-weight: 800;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;

        @media (max-width: 768px) {
          font-size: 30px;
        }
      }

      .kiwiBullets {
        flex-grow: 1;
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
        .bulletContent {
          font-family: Poppins;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: -0.005em;
          text-align: left;
          color: #fff;
          margin-top: 20px;
        }
      }
    }

    .rightInfo {
      flex: 1;

      > div {
        padding: 30px;
      }

      .label {
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: -0.005em;
        text-align: left;
      }

      .description {
        font-family: Roboto;
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: 23px;
        letter-spacing: -0.005em;
        text-align: left;
        color: #fff;
      }
    }
  }

  #mobileImages {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 33.333%));
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 500px) {
      grid-template-columns: 1fr;
    }
  }
}

#dontStop {
  font-family: Poppins;
  font-size: 50px;
  font-style: normal;
  font-weight: 900;
  line-height: 70px;
  letter-spacing: -0.005em;
  text-align: left;
  padding: 50px;

  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 30px;
    padding: 20px;
  }

  #sectionParent {
    display: flex;
    padding-top: 50px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    > div {
      flex: 1;
      img {
        width: 100%;
      }
    }
  }

  #nextJob {
    font-family: Poppins;
    font-size: 50px;
    font-weight: normal;
    line-height: 70px;
    letter-spacing: -0.005em;
    text-align: left;
    background: linear-gradient(91.66deg, #3bd3c3 -2.53%, #4c80ae 59.87%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 768px) {
      font-size: 30px;
      line-height: 30px;
      display: block;
      width: 100%;
    }

    .nextJobName {
      border-bottom: 3px solid #4c80ae;
      font-weight: normal;
    }
  }

  #nextJobDesc {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;

    @media (max-width: 768px) {
      padding-left: 0;
    }

    p {
      color: #fff;
    }

    #nextJobTitle {
      font-family: Poppins;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 51px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 20px;

      @media (max-width: 768px) {
        margin-top: 10px;
        font-size: 30px;
      }
    }

    .seeNextLink {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #fff;
      text-align: right;
      text-decoration: underline;
      margin-bottom: 10px;
    }
  }
}
