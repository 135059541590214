.thjonustan {
  background-color: black;
  color: #fff;

  .introSection {
    max-width: 1400px;
    margin: auto;
    min-height: calc(100vh - 100px) !important;
    .title {
      background: linear-gradient(91.66deg, #3bd3c3 -2.53%, #4c80ae 59.87%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: Poppins;
      font-size: 50px;
      font-weight: 600;
      line-height: 51px;
      letter-spacing: 0em;
      text-align: left;
    }

    .subTitle {
      color: #fff;
      text-transform: uppercase;
      font-family: Poppins;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 51px;
      letter-spacing: 0em;
      text-align: left;
      word-break: break-all;
    }

    .intro {
      margin-top: 30px;
      font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 300;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}
