#podcastStudio {
  background-color: black;
  min-height: calc(100vh - 100px);

  .kubbur {
    .title {
      font-family: Poppins;
      font-size: 50px;
      font-style: normal;
      font-weight: 800;
      line-height: 70px;
      letter-spacing: 0em;
      text-align: left;
      background: linear-gradient(91.66deg, #3bd3c3 -2.53%, #4c80ae 59.87%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .content {
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  #shows {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 350px));
    justify-content: center;
    gap: 70px;
    max-width: 1400px;
    margin: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #000;

    .show {
      text-align: center;
      .showImage {
        padding-bottom: 100%;
        height: 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50px;
        overflow: hidden;
      }

      .showTitle {
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        color: #fff;
        margin-top: 20px;
      }

      .showNames {
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: center;
        color: #747474;
      }
    }
  }
}
